<template>
  <div id="property-promo-page" v-show="!loading">
    <div class="vx-row mt-base" v-show="Object.keys(property_promotions).length == 0">
      <div class="vx-col w-full">
        <img class="block m-auto" :src="emptyDataImg" alt="" width="350" height="350">
        <h4 class="text-3xl font-light text-center">Promotion not available</h4>
      </div>
    </div>

    <div class="vx-row" v-show="Object.keys(property_promotions).length > 0">
      <div class="vx-col w-full">
        <div class="vx-row mb-5 pl-5">
          <h1 class="text-2xl font-bold">
            Choose promotion
          </h1>
        </div>

        <div class="vx-row">
          <div
            class="vx-col w-full mb-base"
            v-for="(property_promotion, i) in property_promotions"
            :key="property_promotion.id"
          >
            <!-- Property Promo Category -->
            <div class="vx-row mb-5 pl-1">
              <div class="vx-col w-full mb-1">
                <h3 class="text-xl font-semibold">{{ i }}</h3>
              </div>
              <div class="vx-col w-full">
                <span class="block"
                  >Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Itaque, consequatur? Temporibus quaerat itaque quo
                  labore</span
                >
              </div>
            </div>

            <!-- List of Property Promotion -->
            <div
              :class="[
                'vx-row',
                property_promotion.length - 1 !== i ? 'mb-base' : ''
              ]"
              v-for="(promotion, i) in property_promotion"
              :key="i"
            >
              <div class="vx-col w-full">
                <div class="vx-card p-5">
                  <div class="vx-row items-center">
                    <!-- Property Promotion Icon and Title -->
                    <div class="vx-col w-full md:w-4/12">
                      <div class="vx-row items-center">
                        <!-- Icon -->
                        <div class="vx-col w-full md:w-6/12">
                          <img :src="url" alt="" />
                        </div>

                        <!-- Title -->
                        <div class="vx-col w-full md:w-6/12">
                          <h3 class="text-xl font-semibold mb-2">
                            {{ promotion.title }}
                          </h3>
                          <p>
                            {{ promotion.subtitle }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <!-- Property Promotion Info -->
                    <div class="vx-col w-full md:w-5/12">
                      <div class="vx-row items-center">
                        <!-- Date info -->
                        <div class="vx-col w-full md:w-6/12">
                          <div class="vx-row">
                            <!-- Bookable date -->
                            <div class="vx-col w-full mb-2">
                              <p class="text-base font-medium">
                                Bookable periode
                              </p>
                              <span class="font-light">
                                {{ promotion.bookable_periode }}
                              </span>
                            </div>

                            <!-- Stay date -->
                            <div class="vx-col w-full">
                              <p class="text-base font-medium">
                                Stay dates
                              </p>
                              <span class="font-light">
                                {{ promotion.stay_date }}
                              </span>
                            </div>
                          </div>
                        </div>

                        <!-- Property Promotion Description -->
                        <div class="vx-col w-full md:w-6/12 m-auto">
                          <p>{{ promotion.description }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="vx-col w-full md:w-3/12 m-auto">
                      <vs-button
                        type="border"
                        @click="
                          $router.push({
                            name: 'vancynet-add-promo-rate-plan',
                            params: {
                              in: 'path',
                              propertyPromotionID: $secure.encrypt(promotion.id)
                            }
                          })
                        "
                      >
                        Add Promotion
                      </vs-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

import emptyDataImg from '@/assets/images/pages/search.svg'

export default {
  name: "PropertyPromotionIndex",

  data() {
    return {
      property_promotions: [],
      url: "https://dummyimage.com/135x135/dedede/383838.png",
      loading: true,
      emptyDataImg: emptyDataImg
    };
  },

  methods: {
    getData() {
      this.$vs.loading({
        type: 'sound'
      })

      axios
        .get(`vancynet/promo-rate-plan/new`)
        .then(({ data: res }) => {
          this.property_promotions = res.data;
        })
        .catch(err => this.$catchErrorResponse(err))
        .finally(() => {
          this.loading = false
          this.$vs.loading.close()
        })
    }
  },

  created() {
    this.getData();
  }
};
</script>

<style></style>
